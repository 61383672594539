<template>
  <div class="h5 mt-1 flex-row align-items-center">
    <div class="container">
      <b-row class="mt-5 justify-content-center">
        <b-col md="6" v-if="token==null">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">404</h1>
            <h4 class="pt-3">Oops! You're lost.</h4>
            <p class="text-muted">The page you are looking for was not found.</p>
          </div>
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>
                <i class="fa fa-search"></i>
              </b-input-group-text>
            </b-input-group-prepend>
            <input id="prependedInput" class="form-control" size="16" type="text" placeholder="What are you looking for?">
            <b-input-group-append>
              <b-button variant="info">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row>
        <b-card v-if="show">
          <div class="mt-2">Hansan.vn thông báo, tài khoản của quý khách đã được kích hoạt thành công, bạn có thể sử dụng để đăng nhập vào hệ thống để sử dụng ngay.</div><br/>
          <div class="mt-2">Chúng tôi xin chân thành cảm ơn sự tin dùng của bạn.</div><br/>
          <div class="mt-2">Trưởng nhóm phát triển hệ thống [sang cao].</div><br/><br/>
          <div class="mt-5">Tiếp tục qua trang đăng nhập <a class="btn btn-primary" href="/login"> <i class="fa fa-hand-o-right"> Đăng nhập</i></a></div>
        </b-card>
      </b-row>
    </div>
  </div>
</template>

<script>
  import router from '../../router'

  export default {
    name: 'ConfirmEmail',
    components: {},
    data () {
      return {
        token:null,
        show:false
      }
    },
    async mounted () {
      debugger
      let app=this
      let token=app.$route.query.token
      if(!token) return

      app.token=token
      let res= await app.confirmEmail(token)
      if(res && res.data && res.data.ok){
        debugger
        let user_db=res.data.user
        app.show=true
      }else{
        this.token=null
        app.$store.commit('st_authentication/login_failure')
      }
    },
    methods: {
      async confirmEmail(token){
        return await this.$store.dispatch(`st_user/confirm_email`,token)
      }
    }
  }
</script>
